<template>
    <div>
        <div class='cargaDocumentos' v-bind:class="[claseGeneral]">
            <div class='cargaDocumentosPrincipal' v-bind:class="[claseGeneral]" :id="item.document_id">
                <v-form ref="formUpload">
                    <div v-for="(archivo, idx) in chosenFiles" :key="idx">
                        <span>
                            Formatos aceptados: {{ archivo.typeDocument }}
                            <br />
                            No mayor a {{ archivo.sizeDocumentMb }} Mb.
                        </span>
                        <div :id="'fileNameDiv-' + item.document_id"></div>
                        <br />
                        <v-file-input class="uploadBot" v-model="archivo.file"
                            :label="'Adjuntar' + (archivo.required ? '*' : '')" truncate-length="50" dense
                            :accept="archivo.typeDocument" @change=" archivo.message = '';"
                            :disabled="archivosCargado || cargando">
                        </v-file-input>
                        <span class="msjError"
                            v-if="archivo.file != undefined && archivo.message != null && archivo.message.length > 0 ">{{
                                archivo.message }}</span>
                        <br />
                        <br />
                        <br />
                    </div>
                    <v-btn block right @click="validaRequeridos()" :disabled="archivosCargado" class="cargaDocumentoBot"
                        v-bind:class="[claseGeneral]" :loading="cargando">Enviar documento</v-btn>
                </v-form>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import TranslateService from "@/services/translate.service";
export default Vue.component("uploadBot", {
    props: [
        "item",
        "claseGeneral",
        "keyChat"
    ],
    data: () => ({
        sizeArchivo: 2000000,
        archivosCargado: false,
        chosenFiles: [],
        response: [],
        mensaje: '',
        cargando: false
    }),
    mounted() {
        this.sizeArchivo = (this.item.data.upload.sizeDocumentMb * 1000000);
        this.chosenFiles = this.item.data.upload.files;
        this.$emit("footerDisabled", this.item.data.upload.footerDisabled);

    },
    methods: {

        validaRequeridos() {
            var procesado = 0;
            var aux = true;
            var numRegistros = this.chosenFiles.length;

            this.chosenFiles.forEach((archivo) => {
                this.sizeArchivo = (archivo.sizeDocumentMb * 1000000);
                var chosenFile = archivo.file;

                if (archivo.required && (chosenFile == undefined || chosenFile == null || chosenFile.type == undefined || chosenFile.type == null)) {
                    archivo.message = "El documento es requerido.";
                    aux = false;
                } else if (chosenFile.size > this.sizeArchivo) {
                    archivo.message = "¡El archivo pesa más de " + archivo.sizeDocumentMb + " MB!";
                    aux = false;
                } else if (!this.validaTipoArchivo(archivo.typeDocument, chosenFile.name)) {
                    archivo.message = "El archivo debe ser del tipo: " + archivo.typeDocument;
                    aux = false;
                }
                if ((procesado + 1 >= numRegistros) && aux) {
                    this.importTxt();
                }
                procesado++;
            })
        },

        importTxt() {
            var x = 0;
            var procesado = 0;
            this.cargando = true;

            this.chosenFiles.forEach((archivo) => {
                var chosenFile = archivo.file;
                var newBlob = chosenFile.slice(0, chosenFile.size, chosenFile.type);
                var extension = chosenFile.name.substring(chosenFile.name.lastIndexOf('.'));
                var newFile = new File([newBlob], x + extension, {type: chosenFile.type});
                chosenFile = newFile;
                var formData = new FormData();
                formData.append("files", chosenFile);
                formData.append("keyChat", this.keyChat);
                TranslateService.UrlUploadFilesBot(formData, this.item.data.upload.typeUpload).then(resp => {
                    let urlArchivo = "hideMessageForClient::documentUrl:" + resp.data[0];
                    let urlFile = { archivo: urlArchivo, url: resp.data[0] };
                    this.$emit("SaveMesssage", urlArchivo);
                    this.response.push(urlFile);
                    x++;
                    this.validaProceso(x, procesado);
                    procesado++;
                }).catch(error => {
                    this.validaProceso(x, procesado);
                    if (chosenFile.name != undefined && chosenFile.name != null) {
                        console.error("ocurrio un error al subir el documento \n ####", error);
                    } else {
                        console.warn("No contiene archivo.");
                    }
                    procesado++;
                });
            });
        },
        validaProceso(x, procesado) {
            if (procesado + 1 >= this.chosenFiles.length) {
                let aux = JSON.stringify(this.response);
                console.log(aux);

                let msg = "";

                /*if(x > 1)
                {
                    msg = "Se enviaron " + x + " documentos";
                }
                else
                {
                    msg = "Se envío " + x + " documento";
                }*/
                
                this.$emit("enviarDocumentosBot", aux);
                //this.$emit("SaveMesssage", msg);
                this.$emit("footerDisabled", false);
                this.cargando = false;
                this.archivosCargado = true;
            }
        },
        validaTipoArchivo(documentTypes, fileName) {
            const validTypes = documentTypes.split(',');
            for (let i = 0; i < validTypes.length; i++) {
                if (fileName.includes(validTypes[i])) {
                    return true;
                }
            }
            return false;
        }
    }
})
</script>
<style scoped>
.v-input--is-label-active.v-input--is-dirty.v-input--is-focused.v-input--dense.theme--light.v-text-field.v-text-field--is-booted.v-file-input.primary--text,
.v-application.primary--text {
    color: rgba(0, 0, 0, 0.6) !important;
    caret-color: rgba(0, 0, 0, 0.6) !important;
}

.cargaDocumentos {
    background-color: #FDE2D2;
    max-width: 310px;
    min-width: 150px;
    border-radius: 4px;
    margin: 2px 0 10px 0px;
    padding: 8px 20px;
    font-family: 'Roboto Regular', 'Source Sans Pro', 'Open Sans', 'SF Pro Regular';
}

.cargaDocumentosPrincipal {
    display: block;
    color: #FF8028;
    font-size: 14px;
    width: 100%;
}

.msjError {
    color: #ff0000;
    font-size: 12px;
}

.cargaDocumentoBot.claro.ClaroBot {
    background-color: #0097A9 !important;
    color: #FFFFFF;
}
</style>
